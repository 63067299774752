function ButtonDelete() {

  return (
    <button
      id='delete'
      className={'movies-card__button-delete'}>
    </button>
  )
}

export default ButtonDelete